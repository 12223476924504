import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home/homePage';
import AboutusPage from './pages/aboutus/aboutUsPage';
import AccountingPage from './pages/accounting/accountingPage';
import SoftwareDev from './pages/softwaredev/softwaredev.jsx';
import SocialMediaPage from './pages/social-media/social-media';
import TechSupport from './assets/css/pages/techsupport/techsupport';
import GraphicDesign from "./pages/graphicdesign/graphicdesign";
import ComingSoonPage from "./pages/coming-soon/comingsoon";
import Contact from "./pages/contact/contact.jsx"
import Blogs from './pages/blogs/Blogs.jsx';
import BlogsDetails from './pages/blogs/BlogsDetails.jsx';
import NotFound from './components/NotFound.jsx';
import PostNewSubscriptionForm from './components/forms/NewSubscriptionForm';
import NewSubscriptionForm from './components/forms/NewSubscriptionForm';
import UnSubcriptionForm from './components/forms/unsubscription.jsx';


function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/blogs/' element={<Blogs />} />
        <Route path='/blogs/details/:blogSlug/' element={<BlogsDetails />} />
        <Route path="/about-us/" element={<AboutusPage />} />
        <Route path='/accounting/' element={<AccountingPage />} />
        <Route path="/software-dev/" element={<SoftwareDev />} />
        <Route path='/digital-marketing/' element={<SocialMediaPage />} />
        <Route path='/tech-support/' element={<TechSupport />} />
        <Route path='/graphic-design/' element={<GraphicDesign />} />
        <Route path='/coming-soon/' element={<ComingSoonPage />} />
        <Route path='/contact/' element={<Contact />} />
        <Route path='/sub/' element={<NewSubscriptionForm />} />
        <Route path='/unsub/' element={<UnSubcriptionForm />} />
        <Route path='*' element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Main;

