import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Header } from "../../components/header";
import "../../assets/css/main/main.css";
import { Link } from 'react-router-dom';
import "../../assets/css/contact/contact.css"
import Footer from '../../components/footer';
import SEO from "../../SEO/metaTags";

// icons
import { FaLocationDot, FaMapLocationDot, FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";

function Contact() {
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [response, setResponse] = useState('')
  const [isSending, setIsSending] = useState(false)

  const apiUrl = "https://csr-xhww.onrender.com/api"

  const handleMessages = async (e) => {
    e.preventDefault()
    setIsSending(true)

    try {
      const res = await fetch(`${apiUrl}/contact/messages/new/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'name': name,
          'phone_number': phoneNumber,
          'email': email,
          'message': message
        })
      })

      const data = await res.json()
      if (res.ok) {
        setResponse(data.message)
        setIsSending(false)
        setName('')
        setPhoneNumber('')
        setEmail('')
        setMessage('')

        setTimeout(() => {
          setResponse('')
        }, 2000)
      }
    } catch (error) {
      setIsSending(false)
      console.log(error)
    }
  }

  return (
    <div className="contact-us">
      <SEO
        title='Contact us'
        description='Reach out for top outsourcing services in accounting, software development, digital marketing, and consulting and training solutions in Rwanda.'
        keywords='Contact our team for accounting outsourcing, software development, and digital marketing services.'
        url='https://www.csrlimited.com/contact'
      />
      <div className="header-container">
        <Header />
        <div className="title-container">
          <div className="container">
            <li><Link to="/">Home / Get in touch</Link></li>
          </div>
          <div className="container">
            <div className="title">
              <h1>Get in touch with us</h1>
              <p>
                Thank you for your interest in contacting CSR Ltd, please reach us at the below <br />
                contacts we will be happy to discuss this with you.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="contacts trust">
        <h1>Get in touch with us</h1>
        <p>
          Thank you for your interest in contacting CSR Ltd, please reach us
          at the below contacts we will be happy to discuss this with you.
        </p>

        <div className="location-form">
          <div className="location">
            <div className="location-text">
              <div className="location-title">
                <div className="location-icon">
                  <FaLocationDot />
                </div>
                <h1>Rwanda Office</h1>
              </div>
              <p>Gisimenti</p>
              <p>37 KG 175 Street, Kigali</p>
            </div>

            <div className="location-text">
              <div className="location-title">
                <div className="location-icon">
                  <FaMapLocationDot />
                </div>
                <h1>USA Office</h1>
              </div>
              <p>2510 E Independence st</p>
              <p>816 E. Federal Shawnee,</p>
              <p>OK 74804</p>
            </div>

            <div className="location-text">
              <div className="location-title">
                <div className="location-icon">
                  <IoMdMail />
                </div>
                <h1>Email Us</h1>
              </div>
              <img className="contact-image" src="/assets/img/home/email.png" alt="" />
            </div>

            <div className="location-text">
              <div className="location-title">
                <div className="location-icon">
                  <IoMdMail />
                </div>
                <h1>Call Us</h1>
              </div>
              <p>Phone: +250791902159</p>
              <p>Phone: 405.777.4097</p>
            </div>
          </div>

          <div className="contact-form">
            {response && (
              <p className="message">{response}</p>
            )}
            <form onSubmit={handleMessages}>
              <label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Full name"
                  className="input-field"
                />
              </label>
              <label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email address"
                  className="input-field"
                />
              </label>
              <label>
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder=" Phone number with country code"
                  className="input-field"
                />
              </label>

              <label>
                <textarea
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder=" Your message"
                  className="input-field"
                />
              </label>
              <button type='submit' className="send-message-btn">
                {isSending ? 'Sending...' : 'Send message'}
              </button>
            </form>
          </div>

        </div>
        <div className="trust">
          <iframe
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d463.7506059040496!2d30.10970384127117!3d-1.9612422066287096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca6545e70668d%3A0xa60e0cff908dca41!2s37%20KG%20175%20St%2C%20Kigali!5e1!3m2!1sen!2srw!4v1716304876047!5m2!1sen!2srw"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

      </section>
      <Footer />
    </div>
  )
}

export default Contact
