import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({title, description, keywords, url}) => {
    return (
        <Helmet>
            {/* Title Tag */}
            <title>{title}</title>

            {/* Meta Description */}
            <meta name="description" content={description} />

            {/* Meta Keywords */}
            <meta name="keywords" content={keywords} />

            {/* Open Graph Tags */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="https://www.csrlimited.com/image.jpg" />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />

            {/* Twitter Card Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content="https://www.csrlimited.com/image.jpg" />

            {/* Canonical Tag */}
            <link rel="canonical" href={url} />

            {/* Robots Meta Tag */}
            <meta name="robots" content="index, follow" />

            {/* Charset Meta Tag */}
            <meta charset="UTF-8" />

            {/* Viewport Meta Tag */}
            <meta name="viewport" content="width=device-width, initial-scale=1" />

            {/* Author Meta Tag */}
            <meta name="author" content="CSR Ltd" />

            {/* Language Tag */}
            <meta http-equiv="Content-Language" content="en" />

            {/* Alternate Hreflang Tags */}
            <link rel="alternate" href="https://www.csrlimited.com/en" hreflang="en" />
            <link rel="alternate" href="https://www.csrlimited.com/fr" hreflang="fr" />

            {/* Schema Markup (JSON-LD for Organization) */}
            <script type="application/ld+json">
                {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "CSR Ltd",
            "url": "https://www.csrlimited.com",
            "logo": "https://www.csrlimited.com/logo.jpg",
            "sameAs": [
              "https://www.facebook.com/csrlimited",
              "https://www.twitter.com/csrlimited",
              "https://www.linkedin.com/company/csrlimited"
            ],
            "contactPoint": {
              "@type": "Kigali Rwanda",
              "telephone": "+250791902159",
              "contactType": "Customer Service"
            }
          }
        `}
            </script>
        </Helmet>
    );
};

export default SEO;
