import React from 'react'
import myImage from "../../assets/css/pages/home/softwaredev/images/software-dev-img.png";
import Image2 from "../../assets/css/pages/home/softwaredev/images/software.svg";
import "../../assets/css/software/softwaredev.css"
import '../../assets/css/main/main.css'
import { SecondHeader } from '../../components/header';
import Footer from "../../components/footer"
import { Link } from 'react-router-dom';
import SEO from '../../SEO/metaTags';

const SoftwareDev = () => {
  return (
    <>
      <SEO
        title='Software Development'
        description='Explore our outsourcing services in Software development as for businesses and individuals.'
        keywords='We provide innovative and efficient solutions to meet your software development needs.'
        url='https://www.csrlimited.com/software-dev/'
      />
      <div className="hero-1">


        <div className="hero-container">
          <SecondHeader />
          <div className="software-text">
            <h1 className='header-dev'>Experienced Software  <br />  {"{ Developers }"} {"[ Designers ]"}</h1>
            <p className='paragraph'>Whether you are  looking to improve your
              online presence or streamline your business
              operations, our team
              is here to help you achieve your goals</p>

            <Link to="/contact">
              <button className='btn-blue'>
                Get in touch

              </button>
            </Link>
          </div>


          <div className='develoment-image'>
            <img src={myImage} alt="" />
          </div>
        </div>



        <div className='section-what-we-do'>

          <h1 className='
          '>What We Do</h1>
          <p className='paragrap-we-offer'>We provide innovative and efficient solutions to <br />
            meet your software development needs. Our department  <br />
            offers a full range of services, including website design and development, <br /> mobile app development,
            and maintenance, using the latest industry-leading technologies.</p>
        </div>


        <div className="card-services">
          <h1 className='title-service'>Services</h1>

          <div className='container-service'>


            <div className='single-service1'>
              <i className="fas fa-pencil-ruler"></i>
              <h3>Mockup Design</h3>
              <p className='fist-paragraph'> Our designers create visually appealing,
                user-friendly  <br />
                mockups for websites and mobile apps to <br />
                reflect our clients' vision.</p>
            </div>

            <div className='single-service2'>
              <i className="fas fa-code"></i>
              <h3>Web Development</h3>
              <p>Our developers use leading tech to
                build high-quality websites and mobile apps,
                ensuring optimal performance</p>
            </div>



            <div className='single-service3'>
              <i className="fas fa-server"></i>
              <h3>Hosting</h3>
              <p>We offer reliable, secure hosting for
                websites and mobile apps,
                keeping them accessible 24/7.</p>
            </div>


            <div className='single-service4'>
              <i className="fas fa-network-wired"></i>
              <h3>Maintenance</h3>
              <p>We provide ongoing maintenance
                and support for
                websites and mobile apps,
                keeping them up-to-date and secure.</p>
            </div>
          </div>


        </div>



        <div className='blue-background'>
          <div className='container-cta'>

            <div className='blue-container'>
              <h3 className='whitetext'>Ready to elevate your digital presence and achieve your business goals?</h3>
              <p className='whitetext'>Transform your digital presence with
                our cutting-edge software solutions.
                Contact us today to request a quote and take the
                first step towards a brighter future for your business</p>

              {/* <button className="btn-white">Get in Touch</button> */}
            </div>

          </div>

        </div>


        <div className='process-section'>
          <h1 className='haeding-process'>Our Process </h1>
          <div className='Process-container'>

            <div className='Process-text'>
              <div className='single-process'>

                <div className='number'>1</div>
                <div className='process-content'>
                  <h4>Discovery & Planning</h4>
                  <p className='process-paragraph'>We collaborate with clients to
                    grasp their business needs and objectives, <br />
                    then tailor a plan for their software project.</p>
                </div>

              </div>

              <div className='single-process'>
                <div className='number'>2</div>
                <div className='process-content'>
                  <h4>Design</h4>
                  <p className='process-paragraph'>Our skilled designers craft mockups that capture
                    our clients' vision with style and user-friendliness</p>
                </div>

              </div>

              <div className='single-process'>
                <div className='number'>3</div>
                <div className='process-content'>
                  <h4>Design and discovery  </h4>
                  <p className='process-paragraph'>We collaborate with clients
                    to grasp their business needs and
                    objectives, then tailor a plan for their software project. </p>
                </div>
              </div>

              <div className='single-process'>
                <div className='number'>4</div>
                <div className='process-content'>
                  <h4>Development</h4>
                  <p className='process-paragraph'>We employ top-tier
                    technologies to develop websites and
                    mobile/web apps with exceptional quality and performance. </p>
                </div>

              </div>

              <div className='single-process'>
                <div className='number'>5</div>
                <div className='process-content'>
                  <h4>Testing and Launch</h4>
                  <p className='process-paragraph'>We test, launch,
                    and maintain software to ensure
                    quality, security, and up-to-date functionality. </p>
                </div>

              </div>

            </div>



            <div className='single-image'>
              <div className='image-container'>
                <img src={Image2} alt="" />
              </div>

            </div>



          </div>


        </div>

      </div>
      <Footer />
    </>

  )
}

export default SoftwareDev