import React from "react";
import "../../assets/css/pages/accounting/accounting.css";
import Footer from "../../components/footer";
import { Header } from "../../components/header";
import { Helmet } from "react-helmet";
import SEO from "../../SEO/metaTags";


// import accountant from "../../../public/assets/img/accounting/accountant.jpg";
// import ConsultingImg from "../../../public/assets/img/accounting/consulting-img.png";
// import ControllerImg from "../../../public/assets/img/accounting/Controller-img.png";
// import ImgBookKipping from "../../../public/assets/img/accounting/img-book-keeping.png";
// import TaxImg from "../../../public/assets/img/accounting/tax-img.png";

const AccountingPage = () => {
    return (
        <div>
            <SEO
                title="Accounting Services"
                description="Explore our outsourcing services in accounting for large and small businesses as well as individuals."
                keywords="Our Accounting services include Financial Consultancy, Bookkeeping, Tax Advisory, Payroll Management and Business Plan."
                url="https://www.csrlimited.com/accounting/"
            />
            <Header />


            <section className="hero">
                <div className="container">
                    <h1 className="heading hero-heading">Finance and Accounting Services</h1>
                    <p className="hero-text">
                        We are fully committed to helping you succeed by performing the critical
                        accounting functions that keep you financially solvent and compliant with financial regulations.
                    </p>
                    <div className="hero-btns">
                        <div className="btn">
                            <button className=" btn-hero">
                                <a href="/contact/">Get in touch</a>
                            </button>
                        </div>
                    </div>


                </div>
            </section>
            <section className="services">
                <div className="container">
                    <h1 className="heading">Our Services</h1>
                    <p className="services-paragraph">
                        Comprehensive Staffing Resources LTD is proud to be an accounting agency that helps business clients make sound financial decisions daily. We are fully committed to helping you succeed by performing the critical accounting functions that keep you financially solvent and compliant with financial regulations.
                    </p>
                    <div className="row">
                        <div className="col">

                            <img src="/assets/img/accounting/img-book-keeping.png" alt="" />
                        </div>
                        <div className="col">
                            <h2 className="col-title">Accounting & book-keeping</h2>
                            <p>
                                Welcome to our accounting and bookkeeping services website. We specialize in providing reliable and accurate financial services to help businesses manage their finances effectively. Our team of experienced professionals is committed to providing personalized solutions to meet our clients' unique needs. Contact us today to learn more about how we can help you streamline your financial management and achieve your business goals.
                            </p>
                            {/* <a href="/accounting-book-keeping/">
                                <button>Learn more</button>
                            </a> */}
                        </div>
                    </div>
                    <div className="row row-reverse">
                        <div className="col">
                            <h2 className="col-title">Controller Services</h2>
                            <p>
                                CSR recommends exceptional controller-level services provided by specialists that serve as accounting minds and oversee the preparation of financial statements. Furthermore, the controller service also includes observing internal controls, participating in the budgeting process, analyzing financial data, and valuing.
                            </p>
                            <p>
                                From supervision of accounting and bookkeeping processes to examining strategic goals to assisting with ensuring compliance with established budgets and growth goals, controllers are the influence that operates perfect financial health. CSR's controller-level services provide businesses with comprehensive accounting knowledge that also acts as a complete in-house accounting department.
                            </p>
                            {/* <a href="/controller-services/">
                                <button>Learn more</button>
                            </a> */}
                        </div>
                        <div className="col">
                            <img src="/assets/img/accounting/Controller-img.png" alt="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <img src="/assets/img/accounting/consulting-img.png" alt="" />
                        </div>
                        <div className="col">
                            <h2 className="col-title">Financial Consulting</h2>
                            <p>
                                Our financial consulting services are designed to provide you with expert analysis and advice regarding your company’s strategic decisions.
                            </p>
                            <p>
                                As you consider exploring business opportunities, investments, and partnerships, it is important to understand the short, medium, and long-term ramifications of your decisions.
                            </p>
                            <p>
                                Our financial consulting services make it easy to understand the financial impact your decisions will have on your company’s future.
                            </p>
                            {/* <a href="/financial-consulting/">
                                <button>Learn more</button>
                            </a> */}
                        </div>
                    </div>
                    <div className="row row-reverse">
                        <div className="col">
                            <h2 className="col-title">Tax Preparation</h2>
                            <p>
                                Commercial tax preparation can be extremely complex. Records and submissions must be prepared carefully to minimize your risk of unforeseen tax liabilities, fees, and penalties.
                            </p>
                            <p>
                                Our team will work with you to prepare complete and accurate corporate filings on a quarterly and annual basis.
                            </p>
                            {/* <a href="/tax-preparation/">
                                <button>Learn more</button>
                            </a> */}
                        </div>
                        <div className="col">
                            <img src="/assets/img/accounting/tax-img.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-summary">
                <div className="container">
                    <h1 className="heading">
                        CSR Limited is proud to be an accounting agency that helps business clients make sound financial decisions daily
                    </h1>
                    <p>
                        An excellent deal of thought went into crafting a proposal for services that adequately meet your accounting needs, and we are confident that you will find precisely what you are looking for in this proposal.
                    </p>
                    {/* <a href="/contact/">
                            <button className="btn-white btn-call">
                                Get in touch
                            </button>
                        </a> */}
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default AccountingPage;
