import React from "react";
import aboutus from "../../../src/assets/img/aboutus.jpg";
import mission from "../../../src/assets/img/mission.jpg";
import kabibi from "../../../src/assets/img/kabibi.webp";
import "../../assets/css/aboutus/aboutus.css";
import { Helmet } from "react-helmet";
import Footer from "../../components/footer";
import { Header } from "../../components/header";
import "../../assets/css/main/main.css";
import Ourteam from "../../components/ourteam";
import { Link } from 'react-router-dom';
import SEO from "../../SEO/metaTags";

const aboutUsPage = () => {
  return (
    <div className="about_us">
      <div className="aboutus_container">
        <div className="section">
          <SEO
            title='About us'
            description='Learn about our mission to provide top-notch outsourcing, consulting, and training services in accounting, software development, and digital marketing.'
            keywords='Our team delivers exceptional outsourcing services, consultancy, and training to meet the unique needs of businesses in Rwanda.'
            url='https://www.csrlimited.com/about-us/'
          />
          {/* <Helmet>
            <title>About US | CSR Limited</title>
          </Helmet> */}
          <div className="header-container">
            <Header />

            <div className="title-container">
              <div className="container">
                <li><Link to="/">Home / About us</Link></li>
              </div>
              <div className="container">
                <div className="title">
                  <h1>Comprehensive staffing Resources Ltd</h1>
                  <p>
                    Our expertise will drive all aspects of your business
                    operations to outstanding<br></br> heights. We strive to keep
                    our services qualitative and accurate, making sure to{" "}
                    <br></br>deliver on time.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="middle_section">
              <div className="image_text">
                <div className="text">
                  <h1>Who are we?</h1>
                  <p>
                    Comprehensive Staffing Resources Ltd (CSR) serves local{" "}
                    <br></br> and foreign companies. Our clients get the
                    advantage of <br></br>working with a company that has a
                    global presence.
                    <br></br> Clients get to work with people of different
                    diversity,<br></br> backgrounds, and skillset while adding
                    reliability and <br></br> credibility to their businesses.
                  </p>
                </div>
                <div className="image">
                  <img src={aboutus} alt=""></img>
                </div>
              </div>
              <div className="misson">
                <div className="image">
                  <img src={mission} alt=""></img>
                </div>
                <div className="text">
                  <h1>Mission</h1>
                  <p>
                    To ensure the total satisfaction and future success of all
                    our <br></br>stakeholders. We are dedicated to creating 1200 jobs<br></br>
                    through fresh graduates in Rwanda which will enable them<br></br> to
                    serve the global business world.
                  </p>
                </div>

              </div>
            </div>
            <div className="vision_value">
              <div className="contents">
                <div className="vision">
                  <h3>Vision</h3>
                  <p>
                    To become the leading global <br></br>outsourcing company who
                    aims at <br></br>
                    empowering fresh graduates, by <br></br>bridging the gap of
                    experience and <br></br>
                    training them for the job market.
                  </p>
                </div>
                <div className="images">
                  <img src={kabibi} alt=""></img>
                </div>
                <div className="values">
                  <h3>values</h3>
                  <p>
                    Family <br></br> Integrity <br></br>Competence <br></br>{" "}
                    Transparency and <br></br>Open Communication
                  </p>
                </div>
              </div>
            </div>
            <div className="lower_section">
              <Ourteam />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default aboutUsPage;
